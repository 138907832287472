//import logo from './logo.svg';
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import './App.css';
import NavBar from './NavBar';
import Footer from './Footer';
import Home from "./pages/Home";
import Development from "./pages/Development";
import About from "./pages/About";
import Projekter from "./pages/Projekter";
// import Button from 'react-bootstrap/Button';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <NavBar/>
      <div className="container">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/development" element={<Development/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/projekter" element={<Projekter/>} />
        </Routes>
      </div>
      <Footer/>
    </>
  );
}

export default App;
