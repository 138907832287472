import "../styles.css";
import React, { /*useState, useEffect,*/ useRef } from "react";
import { Icon } from '@material-ui/core';
//import { LightbulbOutline } from '@material-ui/icons';
import ConstructionIcon from '@mui/icons-material/Construction';

export default function Development() 
{
    const contentRef0 = useRef(null);
    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);
  
    const scrollToContent = (ref) => {
        const offset = 60; // Adjust this offset as needed
        const elementTop = ref.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: elementTop - offset,
            behavior: 'smooth',
        });
      };


    const Gallery = ({ images }) => {
        return (
            <div className="gallery">
            {images.map((image, index) => (
                <img key={index} src={image} alt={`Image ${index}`} />
            ))}
            </div>
        );
    };
    // Array of images
    const images_esterdell = [
        "/images/OtherProjects/EsterdellWorld2_GM2.jpg",
        "/images/OtherProjects/EsterdellWorld3_GM2.jpg",
        "/images/OtherProjects/EsterdellWorld4_GM2.jpg",
        "/images/OtherProjects/EsterdellWorld6_GM2.jpg",
        "/images/OtherProjects/EsterdellWorld5_GM2.jpg",
        "/images/OtherProjects/EsterdellWorld_GM2.png",
        // Add more images as needed
    ];
    // Array of images
    const images_tiles = [
        "/images/OtherProjects/Tiles7_4_GMS2.jpg",
        "/images/OtherProjects/Tiles7_2_GMS2.jpg",
        "/images/OtherProjects/Tiles7_GMS2.png",
        "/images/OtherProjects/Tiles7_6_GMS2.jpg",
        "/images/OtherProjects/Tiles7_3_GMS2.jpg",
        "/images/OtherProjects/Tiles7_5_GMS2.jpg",
        "/images/OtherProjects/Tiles3_(02-02-2018).png",
        // Add more images as needed
    ];
    // Array of images
    const images_networking = [
        "/images/OtherProjects/GameMakerStudio2_(22-06-2018).png",
        // Add more images as needed
    ];
    // Array of images
    const images_skivillage = [
        "/images/OtherProjects/SkiVillage23_(2-1-2021).jpg",
        "/images/OtherProjects/SkiVillage18_(2-1-2021).jpg",
        "/images/OtherProjects/SkiVillage27_(2-1-2021).jpg",
        "/images/OtherProjects/SkiVillage37_(30-1-2021).jpg",
        "/images/OtherProjects/SkiVillage38_(30-1-2021).jpg",
        // Add more images as needed
    ];
    // Array of images
    const images_sims = [
        "/images/OtherProjects/Sims3_(27-02-2022).png",
        "/images/OtherProjects/Sims4_(27-02-2022).png",
        "/images/OtherProjects/Sims6_(27-02-2022).png",
        // Add more images as needed
    ];
    // Array of images
    const images_arqaethegameoffline = [
        "/images/OtherProjects/Eventyr36_(04-11-2021).png",
        "/images/OtherProjects/Eventyr40_(04-11-2021).png",
        "/images/OtherProjects/Eventyr29_(2021-18-01).png",
        // Add more images as needed
    ];

    return (
        <div className="development" ref={contentRef0}>
            <h1>
                Projekter
            </h1>
            <div className="development_description">
                <p>
                    Her har jeg samlet nogle af mine større og mere ambitiøse projekter gennem årene.
                    <br></br> <br></br>
                    Jeg startede oprindeligt med at lave 2D spil i GameMaker og har siden hen fundet glæden ved at lave 3D spil i Unity.
                </p>

                <button className="button" onClick={() => scrollToContent(contentRef1)}>
                    Game Maker
                    </button>
                <button className="button" onClick={() => scrollToContent(contentRef2)}>
                    Unity
                    </button>
                </div>

            <div className="development_section" ref={contentRef1}>
                <h1 className="engine_title">
                    Mine projekter i Game Maker
                </h1>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            World of Esterdell 
                            <br></br>
                            <div className="date_extra">(Fra ca 2015-2016)</div>
                        </h1>
                        <p>Dette var mit allerførste seriøse projekt, som jeg lavede i Game Maker. Det var her jeg fandt ud af at jeg gerne ville fortsætte med at udvikle. Det er dog også det første og eneste spil hvor jeg ikke selv har lavet al grafikken til.</p>
                        <h2 className="projekter_features_text">
                            Features: 
                        </h2>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simpelt WASD Movement <div className="extra_info"></div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Inventory System <div className="extra_info">- (6 slots med mulighed for at trække genstande over til et andet slot (drag and drop))</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Crafting System <div className="extra_info">- (Mulighed for at hugge og samle crystaller op, som efterfølgende kan sælges i byen)</div> <br></br>     
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Fast Travel Systemer <div className="extra_info">- (I form af luftballoner, samt via hest og båd)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simpelt Quest System <div className="extra_info">- (Man kan acceptere 3 quests fra NPC's rundt omkring i verdenen. Man kan dog ikke aflevere dem igen.)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Interact System <div className="extra_info">- (Man kan interagere med NPC's (både questgivers, en merchant og en blacksmithing trainer), krystallerne, fast travel mulighederne (hest og båd), samt en kiste i byen. Derudover kan man gå ind i teltene og tænde/slukke for bålet)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Menu's <div className="extra_info">- (3 menuer som man kan åbne. Bogen nederst i venstre hjørne åbner en oversigt over hvad man har opnået (fx. hvor mange krystaller man har indsamlet), pickaxen åbner en oversigt over hvor langt man er nået i blacksmithing. Den sidste har ikke nogen funktion men skulle være en form for research menu med forskellige ting man skulle kunne gå op i)</div> <br></br> 
                    </div>
                    <Gallery images={images_esterdell}/>
                </div>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Unavngivet RPG 
                            <br></br>
                            <div className="date_extra">(Fra ca 2017-2018)</div>
                        </h1>
                        <p>Dette var et projekt jeg startede op kort tid efter jeg startede på produktionsskole (på en spiludviklings linje) i 2017, og har derefter arbejdet på det sideløbende med at jeg gik på skolen, i ca 1 års tid. Al grafikken som man kan se på billederne herunder er lavet af mig selv.</p>     
                        <h2 className="projekter_features_text">
                            Features: 
                        </h2>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simpelt WASD Movement <div className="extra_info"></div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Health/Energy/Soul System <div className="extra_info">- (Den blå bobbel i midten viser en form for energi/soul, som bruges til at identificere hvor meget 'liv' man har tilbage før man dør. Man mister energi/soul når man bliver ramt af enemies, og får muligheden for at opsamle enemies energi/soul når de dør)</div> <br></br>   
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Combat/Mechanic System <div className="extra_info">- (En skill-bar nederst i venstre hjørne, med mulighed for at slå enemies med sværdet, dodgderoll, blokere enemies angreb, eller hoppe ned i jorden og gemme sig.)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> AI System <div className="extra_info">- (Enemies som vandrer tilfældigt rundt inden for et givent område, og som jagter og angriber når player kommer for tæt på)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simpelt Inventory System <div className="extra_info">- (Man har mulighed for både at samle scrolls op (som skulle fungere som quests), samt runesten (som skulle gøre player stærkere))</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Level System <div className="extra_info">- (Man opnår experince for hvert dræbte enemy)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Hide Mechanic <div className="extra_info">- (Man har muligheden for at hoppe ned i jorden og gemme sig i en mængde tid. Som det kan ses på billed 5, så kan man kun opholde sig under jorden indtil baren løber ud)</div> <br></br>                    
                    </div>
                    <Gallery images={images_tiles}/>
                </div>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Unavngivet Multiplayer Game
                            <br></br>
                            <div className="date_extra">(Fra ca 2018)</div>
                        </h1>
                        <p>Dette var også et projekt jeg lavede mens jeg gik på produktionsskole, og var blot et forsøg på at lave et multiplayer spil, ved at skabe en connection gennem en server og nogle clients.</p>     
                        <h2 className="projekter_features_text">
                            Features: 
                        </h2>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Server & Client Funktionalitet <div className="extra_info">- (Med mulighed for at starte en server op, og derefter connecte en client til serveren, som så spawner en player på både serveren, clienten og andre eventuelle connectede clients. Ens egen player ses som blå mens andre players ses som orange)</div> <br></br>                 
                    </div>
                    <Gallery images={images_networking}/>
                </div>
                <button className="back_to_top" onClick={() => scrollToContent(contentRef0)}>
                    Back to top
                </button>
            </div>
            <div className="development_section" ref={contentRef2}>
                <h1 className="engine_title">
                    Mine projekter i Unity
                </h1>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Ski Village (mobilspil)
                            <br></br>
                            <div className="date_extra">(Fra ca 2019-2021)</div>
                        </h1>
                        <p>Dette var mit første seriøse projekt i Unity, og var primært tænkt som et mobilspil.
                            Det var inspireret af spil som Ski Resort Tycoon, blandet med nogle andre funktionaliteter som man ofte ser i andre mobilspil. 
                            Den eneste grafik jeg ikke selv har lavet er de 3 ikoner på knapperne nede i venstre hjørne.
                        </p>     
                        <h2 className="projekter_features_text">
                            Features: 
                        </h2>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simpelt Kamera Movement <div className="extra_info">- (Med funktioner som zoom og pan med fingrene)</div> <br></br>   
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Menu Systemer <div className="extra_info">- (Der er de 3 menuer nede i venstre hjørne: En menu hvor man kan købe diverse ting til sin village. En menu til achievements, og så en menu til lobby management (hvor man kan se diverse statistikker over sin village))</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Coin System <div className="extra_info">- (Man tjener penge på at have folk boende i hytterne, mens man bruger penge på at købe nye genstande i shoppen.)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Bygge System <div className="extra_info">- (Man kan købe hytter, stenfliser, lygtepæle og skilifter. Når man har valgt sin genstand kan man placere genstanden indefor et grid, hvor der vil komme en rød firkant under genstanden hvis man har placeret den på et ugyldigt sted)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Achievement System <div className="extra_info">- (Med 5 Achievements som man kan opnå)</div> <br></br>                
                        <Icon className="icon"> <ConstructionIcon/> </Icon> AI System <div className="extra_info">- (Små folk vil komme og gå rundt i ens village. De vil så vise et ikon over hovedet, alt efter om de er tilfreds eller utilfredse med stedet)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Dag/Nat Mulighed <div className="extra_info">- (Man kan vælge at skifte fra dag til nat og omvendt)</div> <br></br> 
                    </div>
                    <Gallery images={images_skivillage}/>
                </div>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            ArqaeTheGame Offline
                            <br></br>
                            <div className="date_extra">(Fra ca 2019-2021)</div>
                        </h1>
                        <p>
                            Dette spil minder utrolig meget om det jeg er i gang med at udvikle på nu, det er bare ikke lavet som et multiplayer spil. 
                            Dog har det været med til at skabe hele grundlaget for det multiplayer spil jeg er i gang med nu, da mange af idéerne er blevet bragt videre herfra.
                            På den måde kan man næsten kalde dette for første iteration af ArqaeTheGame.
                            <br></br><br></br>
                            Det har også rigtig mange af de samme features som det nye, hvilket har hjulpet en del i udviklingen af ArqaeTheGame som multiplayer spil.
                            <br></br><br></br>
                            Features i ArqaeTheGame Offline indebærer bland andet:
                            <br></br>
                            Quest System, Skill System, AI's man kan dræbe for loot, Inventory og Equipment System, Minimap, Level & Experience System, Character Creation, samt Crafting System.  
                        </p>     
                    </div>
                    <Gallery images={images_arqaethegameoffline}/>
                </div>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Simulation Game
                            <br></br>
                            <div className="date_extra">(Fra ca 2021-2022)</div>
                        </h1>
                        <p>Dette var blot et forsøg på at lave et simulationsspil, med stor fokus på AI. Det er primært inspireret af andre simulationsspil som f.eks. Sims, 
                            dog er det lavet uden fokus på også at kunne bygge. 
                            Tanken var at man skulle kunne styre nogle personer til at gøre bestemte ting i den rækkefølge man sætter dem til det, 
                            og ellers skal de bare kunne styre sig selv alt efter hvordan deres behov udvikler sig. 
                        </p>     
                        <h2 className="projekter_features_text">
                            Features: 
                        </h2>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simpelt Kamera Movement <div className="extra_info">- (Med funktioner som zoom og pan med musen)</div> <br></br>   
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Interact Queue Systeme <div className="extra_info">- (Mulighed for at sætte interaktioner i gang for den aktive person, som så vil komme frem i en kø i toppen)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Character Cration System <div className="extra_info">- (Man kan vælge selv at lave sine personer inden man starter spillet. Man kan lave op til 8 personer, og vælge ting som navn, køn, hudfarve, tøj, samt tøjfarver)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Job System <div className="extra_info">- (Mulighed for at give et job til de personer man styrer, for at kunne tjene penge til husholdningen)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Behov System <div className="extra_info">- (6 barer med forskellige behov som langsomt daler, indtil behovet bliver opfyldt)</div> <br></br>                
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Tidsmanipuleringssystem <div className="extra_info">- (Man kan pause spillet, eller vælge at speede tiden op eller ned)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Relationship System <div className="extra_info">- (Mulighed for at skabe forskellige forhold mellem personerne, ved at lade dem interagere med hinanden)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Trait System <div className="extra_info">- (Man kan tildele op til 3 traits/egenskaber til hver person for at give dem noget personlighed)</div> <br></br> 
                    </div>
                    <Gallery images={images_sims}/>
                </div>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            ArqaeTheGame
                            <br></br>
                            <div className="date_extra">(Fra ca 2022-nu)</div>
                        </h1>
                        <p>
                            Tjek under 'Development' menuen oppe i toppen af siden for at se meget mere til udviklingen af mit nuværende multiplaer RPG spil, 
                            eller gå til forsiden for at få en oversigt over spillets generelle/vigtige features.
                        </p>        
                    </div>
                    {/* <Gallery images={images_sims}/> */}
                </div>
                <button className="back_to_top" onClick={() => scrollToContent(contentRef0)}>
                    Back to top
                </button>
            </div>
        </div>
    )
}