import React, { useState/*, useEffect*/ } from "react";
//import SocialIcon from 'react-social-icons';
import "../styles.css";
import "../development.css";
import "../about.css";
import "../home.css";
//import { Icon, Typography } from '@material-ui/core';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Icon } from '@material-ui/core';
import ConstructionIcon from '@mui/icons-material/Construction';

export default function Home() 
{
    // Button component
    // const CombatButton = ({ btnName, image, onClick }) => {
    //     return (
    //         <button className="combat_button" onClick={onClick}>
    //             <span>{btnName}</span>  {/* Render text inside the button */}
    //             {image && <img src={image} alt={""} />} {/* Render image */}
    //         </button>
    //     );
    // };
  
    // // State to track selected image
    // const [selectedImage, setSelectedImage] = useState("/images/1year_(21-11-2023)/Client2_(21-11-2023).png");

    // // Function to handle button click
    // const handleButtonClick = (image) => {
    //     setSelectedImage(image);
    // };


    // Button component
    const CombatButton = ({ btnName, text, onClick }) => {
        return (
            <button className="combat_button" onClick={onClick}>
                <span>{btnName}</span>  {/* Render text inside the button */}
                {text && <p>{text}</p>} {/* Render text */}
            </button>
        );
    };

    // Define state to keep track of which button was pressed
    const [displayText, setDisplayText] = useState("Quick attacks deal a decent amount of damage while also granting you spirit.");

    // Function to handle button click and update text accordingly
    const handleButtonClick = (text) => {
        setDisplayText(text);
    };

    return (
        <div className="homePage">

            <div className="coverBG">
                {/* { <img className="cover_gameTitle" src="/images/ArqaeTheGameOutline.png" alt=""/> } */}
                {/* { <img className="cover_blackBlur" src="/images/BlackBlur.png" alt=""/> } */}
                {/* <p className="cover_text">
                    Core Features
                </p>     */}
                {/* <Icon className="cover_arrow"> <ExpandMoreIcon style={{ fontSize: 24 }}/> </Icon>               */}
                <div className="cover_vignette"></div>
            </div>

            <div className="core_features_introduction">
                <h1>
                    Core Features
                </h1>
                <div className="core_features_divider_dots"> • • • • • </div>
                <p className="feature_description">
                    Arqae The Game is an upcoming multiplayer RPG targeting both PC and Xbox players.
                    <br></br><br></br>
                    Here, we've gathered some of the core features, particularly those that we believe stand out the most and best represent our vision and intentions for the game.
                    <br></br><br></br>
                    Some of the showcased features below have already been implemented but are in early stages of development (which means they are subject to change), while others are still being actively worked on and will hopefully be ready to be showcased in the near future. 
                    <br></br><br></br>
                    We greatly appreciate any support or feedback. The best way to reach us is by joining our Discord (a link can be found further down).
                </p>
            </div>
            {/* ---------- FACTIONS ---------- */}
            <div className="feature_section" style={{backgroundColor: '#25211d'}}>
                <br></br> {/* Add some space cuz somehow there's a gap between each div */}
                <h1 className="section_title">
                    3 Unique Factions
                </h1>
                <p>
                    Choose one of the 3 main factions before starting the game.
                    <br></br><br></br> 
                    Your chosen faction will determine the camps & cities you can visit, as well as the team you'll fight for in PvP.
                    <br></br><br></br> 
                    Each faction has a unique aesthetic and storyline, offering faction-specific content that differs from the others.

                    {/* Choose the faction that call on you and take part in each faction's unique storylines while you gain access to faction-specific camps, cities & even apperances.  */}
                
                    {/* Each faction's got their own camps and cities */}
                </p>
                <div className="feature_container">
                    <div className="factions_container">
                        <div className="column_3">
                            <div>
                                <h1>
                                    Wilder 
                                </h1>
                                <div className="faction_banner">
                                    { <img className="image_banner" src="/images/BannerWhite.png" alt=""/> }
                                    { <img className="image_factionIcon_wilder" src="/images/Leaf_White.png" alt=""/> }
                                </div>
                                <p>
                                    The Wilders are religious people who perform rituals for the living and offer prayers to the gods of the wild.
                                    <br></br><br></br>
                                    The Wilders live in harmony and peace with nature, nurturing close connections to their inner spirits. 
                                    <br></br><br></br>
                                    They believe in an existence without end, as their spirits live on forever within the nature and animals surrounding them.
                                </p>
                            </div>
                        </div>
                        <div className="column_3">
                            <div>
                                <h1>
                                    New Born 
                                </h1>
                                <div className="faction_banner">
                                    { <img className="image_banner" src="/images/BannerWhite.png" alt=""/> }
                                    { <img className="image_factionIcon_newBorn" src="/images/OptionsIcon_WhiteOutline.png" alt=""/> }
                                </div>
                                <p>
                                    The New Borns are the new generation of Wilders who have lost belief in the gods and natural world. 
                                    <br></br><br></br>
                                    Constantly seeking a new future for their civilization, they choose to embrace mechanisms and technology to forge a whole new existence of life, unlike anything they've ever known before.
                                </p>
                            </div>
                        </div>
                        <div className="column_3">
                            <div>
                                <h1>
                                    Sea Raider 
                                </h1>
                                <div className="faction_banner">
                                    { <img className="image_banner" src="/images/BannerWhite.png" alt=""/> }
                                    { <img className="image_factionIcon_seaRaider" src="/images/Skull_White.png" alt=""/> }
                                </div>
                                <p>
                                    The Sea Raiders are violent yet honorful conquerors. 
                                    <br></br><br></br>
                                    They emerged from the Sea riding the waves, driven by a relentless pursuit of all kinds of valuables.
                                    <br></br><br></br>
                                    In their search they’d do anything to be seen as the strongest individuals, including the use of forbidden magic such as resurrecting and consuming the dead.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ---------- CLASSES ---------- */}
            <div className="feature_section">
                <h1 className="section_title">
                    Classes
                </h1>
                <div className="feature_container">
                    <div className="column_solo">
                        <p>Coming soon...</p>
                        <br></br>
                        <div className="grey_text">
                            <Icon className="icon"> <ConstructionIcon/> </Icon>We are currently deciding whether to implement a classless system. Feel free to Join our Discord to participate in the discussion.
                        </div>
                        <br></br>
                        <a href="https://discord.gg/ptB24ckwMk" target="_blank" rel="noopener noreferrer"> {/*it's recommended to include rel="noopener noreferrer" for security and performance reasons. This prevents the new page from accessing the window.opener property, which could potentially be a security risk, and ensures that the new tab doesn't inherit the referrer information, which can help with privacy.*/}
                            { <img className="img_discord_small" src="/images/DiscordIcon.ico" alt=""/> }  
                        </a>
                    </div>
                </div>
            </div>
            {/* ---------- COMBAT ---------- */}
            <div className="feature_section" style={{backgroundColor: '#25211d'}}>
                <br></br> {/* Add some space cuz somehow there's a gap between each div */}
                <h1 className="section_title">
                    Action-Packed Combat
                </h1>
                <div className="feature_container">
                    <div className="column_solo">
                        {/* Render buttons with corresponding images */}
                        <CombatButton btnName="Quick Attack" onClick={() => handleButtonClick("Quick attacks deal a decent amount of damage while also granting you spirit.")}></CombatButton>
                        <CombatButton btnName="Charged Attack" onClick={() => handleButtonClick("A fully charged attack will not only deal more damage but it will also grant you 1 action point.")}></CombatButton>
                        <CombatButton btnName="Bar-Swap" onClick={() => handleButtonClick("The bar-swap allows you to switch skill bars mid-combat, with each bar based on your two equipped weapons.")}></CombatButton>
                        <CombatButton btnName="8 Active Skills" onClick={() => handleButtonClick("You can have up to 4 skills in each skill bar (currently 5 in the image but that will be changed soon.)")}></CombatButton>
                        <CombatButton btnName="2 Ultimates" onClick={() => handleButtonClick("You can have 1 ultimate in each skill bar, with the ultimate being a more powerful skill but one that takes time to fully charge.")}></CombatButton>
                        <CombatButton btnName="Dodgeroll" onClick={() => handleButtonClick("Dodgeroll can be used for a quick escape or to dodge enemy attacks.")}></CombatButton>
                        <CombatButton btnName="Block" onClick={() => handleButtonClick("Blocking reduces the damage taken when active but has a limited duration. When releasing your block, it slowly recovers.")}></CombatButton>
                        <CombatButton btnName="Bash" onClick={() => handleButtonClick("Bashing an enemy briefly stuns them.")}></CombatButton>
                        <CombatButton btnName="Potion Slot" onClick={() => handleButtonClick("A healing potion grants you instant healing and can be used as a quick boost mid-combat.")}></CombatButton>
                        <br></br>
                        <br></br>
                        {/* Display selected image */}
                        {/* {<img className="selected_combat_image" src={selectedImage} alt="Coming soon..."/>} */}
                        <p className="grey_text">{displayText}</p>
                        <br></br>
                        { <img className="home_image" src="/images/1year3months(21-02-2024)/Client7_(21-02-2024).jpg" alt=""/> }
                    </div>
                </div>
            </div>
            {/* ---------- OPEN WORLD ---------- */}
            <div className="feature_section">
                <h1 className="section_title">
                    Open World
                </h1>
                <div className="feature_container">
                    <div className="column_left_image">
                        { <img className="home_image" src="/images/1year3months(21-02-2024)/Networking_Client40_(21-02-2024).png" alt=""/> }
                    </div>
                    <div className="column_right_text">
                        <h2>
                            Explore Iaman Island {/* Dynamic World Events */}
                        </h2>
                        <p>
                            Iaman Island is currently the main island that all 3 factions start their journey on.
                            <br></br>
                            {/* On the Island you can:  */}
                            <br></br><br></br>
                            • Fight against unwanted enemies and compete against other players.
                            <br></br><br></br>
                            • Complete quests & progresss though your faction story.
                            <br></br><br></br>
                            • Gather ressources: wood, stone, cotton & flex plants, mushrooms, ores and more. 
                            <br></br><br></br>
                            • Explore the cave and ruins while unravelling valuable chests.

                            {/* Explore Iaman Island as you will unravel precious secrets, fight intensely against unwanted enemies, help your faction grow bigger, and hopefully you will then reveal the true story behind this mysterious Island. 
                            <br></br><br></br>
                            Dynamic world events. */}
                        </p>
                    </div>
                </div>
                <div className="feature_container">
                    <div className="column_left_text">
                        <h2>
                            Map Guidance
                        </h2>
                        <p>
                            The map of Iaman Island offers you a full overview of the Island while also indicating which faction currently controls the PvP towers.
                            <br></br><br></br>
                            Additionally, there are icons that will guide you to your camp and other points of interest.
                            <br></br><br></br>
                            The zoom and pan functionality also provides you with full control over the map and which parts of the world you wish to see.
                            {/* Dont ever get lost with the map guidance that gives you the full overview of the Island while also letting you know which faction that currently has conquered the PvP towers. */}
                        </p>
                    </div>
                    <div className="column_right_image">
                        { <img className="home_image" src="/images/1year3months(21-02-2024)/Client1_(21-02-2024).jpg" alt=""/> }
                    </div>
                </div>
            </div>
            {/* ---------- PVP ---------- */}
            <div className="feature_section" style={{backgroundColor: '#25211d'}}>
                <br></br> {/* Add some space cuz somehow there's a gap between each div */}
                <h1 className="section_title">
                    PvP
                </h1>
                <div className="feature_container">
                    <div className="column_left_image">
                        { <img className="home_image" src="/images/1year3months(21-02-2024)/Client41_(21-02-2024).png" alt=""/> }
                    </div>
                    <div className="column_right_text">
                        <h2>
                            Open world PvP {/*Wilderness, Battlegrounds & Events*/}
                        </h2>
                        <p>
                            You can fight all players from factions other that your own.
                            <br></br><br></br>
                            On the island there is also 3 PvP towers, the Northen-, Western- & Eastern Tower, that can be conquered by your faction to offer some PvP activities.
                        </p>
                    </div>
                </div>
                <div className="grey_text">
                    <Icon className="icon"> <ConstructionIcon/> </Icon>We are also planning on adding Duel Systems & Battlegrounds.
                </div>
                <br></br>
            </div>
            {/* ---------- SKILL TREES ---------- */}
            <div className="feature_section">
                <h1 className="section_title">
                    Skill Trees
                </h1>
                <div className="feature_container">
                    <div className="column_left_image">
                        { <img className="home_image" src="/images/1year3months(21-02-2024)/Client9_(21-02-2024).jpg" alt=""/> }
                    </div>
                    <div className="column_right_text">
                        <h2>
                            Mix & Match the Skills of your desire
                        </h2>
                        <p>
                            Each time you use a skill, you will earn experience points for its corresponding skill tree.
                            <br></br><br></br>
                            When you gain a new skill point, you can either unlock a new skill, upgrade an already unlocked skill, or choose to morph a fully upgraded skill.
                            <br></br><br></br>
                            The plan is for you to have 2 class-specific skill trees, 2 weapon-specific skill trees (based on your currently equipped weapons),
                            and 1 blessing-specific skill tree. 
                            <br></br><br></br>
                            Blessings can be found and collected around the world but only 1 blessing can be active at a time. 

                            {/* Choose to level up your 2 class- & 2 weapon-specific skill trees or develop your very own skill-set by collecting blessings scattered around the World. */}
                        </p>
                    </div>
                </div>
                <div className="grey_text">
                    <Icon className="icon"> <ConstructionIcon/> </Icon>Blessings are currently not in the game but will be added soon.
                </div>
                <br></br>
            </div>
            {/* ---------- CRAFTING ---------- */}
            <div className="feature_section" style={{backgroundColor: '#25211d'}}>
                <br></br> {/* Add some space cuz somehow there's a gap between each div */}
                <h1 className="section_title">
                    Customizable Crafting
                </h1>
                <div className="feature_container">
                    <div className="column_solo">
                        <p>Coming soon...</p>
                        <br></br>
                        <div className="grey_text">
                            <Icon className="icon"> <ConstructionIcon/> </Icon> Check out the images under the Development tab for some visuals of the crafting system. Currently, we are exploring other ways to align it with our vision. The plan is to create a fully customizable crafting system that allows you to piece together components to craft an item that matches your specific needs. 
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
            {/* ---------- QUESTING ---------- */}
            <div className="feature_section">
                <h1 className="section_title">
                    Quests & Storylines
                </h1>
                <div className="feature_container">
                    <div className="column_left_image">
                        { <img className="home_image" src="/images/1year3months(21-02-2024)/Client3_(21-02-2024).jpg" alt=""/> }
                    </div>
                    <div className="column_right_text">
                        <h2>
                            Choose your own path
                        </h2>
                        <p>
                            {/* ArqaeTheGame ArqaeTheGame ArqaeTheGame ArqaeTheGame ArqaeTheGame ArqaeTheGame ArqaeTheGame ArqaeTheGame ArqaeTheGame
                            With our dynamic Quest Systems
                            We have envisioned our Quest System to be dynamic and offer multiple paths */}

                            Our goal for questing is to eliminate some of the repetitiveness by offering multiple paths throughout the storylines. 
                            <br></br><br></br>
                            During a storyline you will be given opportunities to choose between different outcomes. Outcomes that will affect the rest of your progress in the story.
                            <br></br><br></br>
                            <div className="grey_text">
                                <Icon className="icon"> <ConstructionIcon/> </Icon> The ability to progress through a storyline is already implemented, but the opportunity to choose your own path though your choices will be made at a later point. 
                            </div>
                        </p>
                    </div>
                </div>
                <div className="feature_container">
                    <div className="column_left_text">
                        <h2>
                            Side Quests
                        </h2>
                        <p>
                            In each camp, there is also a bulletin board that will offer you easily achieveable side quests. 
                            <br></br><br></br>
                            They are a great way to earn both experience and currency.
                            
                            {/* Check in at the bulletin board in your camp to find easily achieveble daily quests or pick up a whole storyline that lets you travel out in the world and experince it like no other. */}
                            
                        </p>
                    </div>
                    <div className="column_right_image">
                        { <img className="home_image" src="/images/1year_(21-11-2023)/Client2_(21-11-2023).png" alt=""/> }
                    </div>
                </div>
            </div>
            {/* ---------- GUILDS ---------- */}
            {/* <div className="feature_section" style={{backgroundColor: '#25211d'}}> */}
                {/*<br></br> {/* Add some space cuz somehow there's a gap between each div */}
                {/* <h1 className="section_title">
                    Guilds
                </h1>
                <div className="feature_container">
                    <div className="column_left_image">
                        { <img className="image_open_world" src="/images/1year_(21-11-2023)/Client2_(21-11-2023).png" alt=""/> }
                    </div>
                    <div className="column_right_text">
                        <p>
                            Join a guild to participate in a community where you will feel higly appreciated. As part of a Guild you are able to join personal events created by your Guild while also helping everyone else in your community to grow and become stronger. 
                            Together you will also be able to build a Guild Hall that suits you perfectly while also fitting all of the special needs of your Guild.
                        </p>
                    </div>
                </div>
            </div> */}
            {/* ---------- DISCORD ---------- */}
            <div className="feature_section" style={{backgroundColor: '#25211d'}}>
                {/* <h1 className="section_title">
                    Discord
                </h1> */}
                <div className="feature_container">
                    <div className="column_solo">
                        <h1 className="section_title">
                            Join us on Discord
                        </h1>
                        <a href="https://discord.gg/ptB24ckwMk" target="_blank" rel="noopener noreferrer"> {/*it's recommended to include rel="noopener noreferrer" for security and performance reasons. This prevents the new page from accessing the window.opener property, which could potentially be a security risk, and ensures that the new tab doesn't inherit the referrer information, which can help with privacy.*/}
                            { <img className="img_discord" src="/images/DiscordIcon.ico" alt=""/> }  
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}