import "../styles.css";

export default function About() 
{
    return (
        <div className="about">
            <div className="about_section">                  
                <div className="about_column_left">
                    <div>
                        <p className="about_title">Game Name:</p>
                        <p>ArqaeTheGame</p>
                    </div>
                    <div>
                        <p className="about_title">Developers:</p>
                        <p>Arqae</p>
                        <p>Arqaena</p>
                    </div>
                    <div>
                        <p className="about_title">Engine:</p>
                        <p>Unity</p>
                    </div>
                    <div>
                        <p className="about_title">Multiplayer:</p>
                        <p>Yes</p>
                    </div>
                    <div>
                        <p className="about_title">Platforms:</p>
                        <p>PC and Xbox</p>
                    </div>
                    <div>
                        <p className="about_title">Release Date:</p>
                        <p>Unannounced</p>
                    </div>
                </div>
                <div className="about_column_right">
                    <h1>
                        About The Game
                    </h1>
                    <hr className="divider"></hr>
                    <p className="about_text">
                        ArqaeTheGame is an upcoming multiplayer RPG targeting both PC and Xbox players. 
                        <br></br> <br></br>
                        We aim to deliver a unique RPG that offers fast-paced action combat, multiple-choice storylines that allow players to make important decisions, a highly customizable crafting system, and a guild system that serves not only as a community but also plays a significant role in the game's events and PvP.
                        <br></br> <br></br>
                        ArqaeTheGame takes inspiration from classics in the MMORPG genre, while still being imbued with our own innovative concepts and designs to craft a truly unique gaming experience.
                    </p>   
                    <h1>
                        About Us {/* Meet The Team */}
                    </h1> 
                    <hr className="divider"></hr>
                    <p className="about_text">
                        Currently, there are 2 developers working on ArqaeTheGame, striving to make it a great and enjoyable game. 
                        <br></br> <br></br>
                        With over 8 years experience in game development, we are deeply passionate about our work and take pride in handling all aspects of the game ourselves, whitout purchasing pre-made assets. 
                        <br></br> <br></br>
                        As a result, we are taking care of both designing, coding, and creating assets & graphic designs. This allows us to maintain maximum control over our work and ensure that everything seamlessly fits into our vision of the game.
                        <br></br> <br></br>
                        <br></br> <br></br>
                    </p>     
                    {/* <div className="aboutUs_section">  
                        <div className="aboutUs_column"> 
                            { <img className="img_arqae" src="/images/Arqae.png" alt=""/> }  
                            <h2>
                                Arqae
                            </h2>
                            <p className="about_text">
                                Something cool about Arqae here...
                            </p>  
                        </div>
                        <div className="aboutUs_column"> 
                        { <img className="img_arqae" src="/images/Arqae.png" alt=""/> }  
                            <h2>
                                Arqaena
                            </h2>
                            <p className="about_text">
                                Something cool about Arqaena here...
                            </p>  
                        </div>
                    </div> */}
                    {/* <div className="ellipse"></div>                    */}
                </div>
            </div>
        </div>
    )
}