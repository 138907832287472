import "../styles.css";
import React, { /*useState, useEffect,*/ useRef } from "react";
import { Icon } from '@material-ui/core';
//import { LightbulbOutline } from '@material-ui/icons';
import ConstructionIcon from '@mui/icons-material/Construction';

export default function Development() 
{
    const contentRef0 = useRef(null);
    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);
  
    const scrollToContent = (ref) => {
        const offset = 60; // Adjust this offset as needed
        const elementTop = ref.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: elementTop - offset,
            behavior: 'smooth',
        });
      };


    const Gallery = ({ images }) => {
        return (
            <div className="gallery">
            {images.map((image, index) => (
                <img key={index} src={image} alt={`Image ${index}`} />
            ))}
            </div>
        );
    };
    // Array of images
    const images_1y3m = [
        "/images/1year3months(21-02-2024)/Client7_(21-02-2024).jpg",
        "/images/1year3months(21-02-2024)/Client3_(21-02-2024).jpg",
        "/images/1year3months(21-02-2024)/Client9_(21-02-2024).jpg",
        "/images/1year3months(21-02-2024)/Client1_(21-02-2024).jpg",
        "/images/1year3months(21-02-2024)/Client41_(21-02-2024).png",
        // Add more images as needed
    ];
    // Array of images
    const images_1y = [
        "/images/1year_(21-11-2023)/Client6_(21-11-2023).png",
        "/images/1year_(21-11-2023)/Client2_(21-11-2023).png",
        "/images/1year_(21-11-2023)/Client3_(21-11-2023).png",
        "/images/1year_(21-11-2023)/Client5_(21-11-2023).png",
        "/images/1year_(21-11-2023)/Client7_(21-11-2023).jpg",
        // "/images/1year_(21-11-2023)/Client4_(21-11-2023).png",
        // Add more images as needed
    ];
    // Array of images
    const images_9m = [
        "/images/9months_(21-08-2023)/Client28_(21-08-2023).png",
        "/images/9months_(21-08-2023)/Client32_(21-08-2023).PNG",
        "/images/9months_(21-08-2023)/Client33_(21-08-2023).png",
        "/images/9months_(21-08-2023)/Client34_(21-08-2023).PNG",
        "/images/9months_(21-08-2023)/Client35_(21-08-2023).png",
        // Add more images as needed
    ];
    // Array of images
    const images_6m = [
        "/images/6months_(21-05-2023)/Client14_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client13_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client15_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client12_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client17_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client20_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client23_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client18_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client19_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client24_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client25_(21-05-2023).png",
        "/images/6months_(21-05-2023)/Client26_(21-05-2023).png",
        // Add more images as needed
    ];
    // Array of images
    const images_3m = [
        "/images/3months_(25-02-2023)/Client10_(25-02-2023).png",
        "/images/3months_(25-02-2023)/Client3_(25-02-2023).png",
        "/images/3months_(25-02-2023)/Client4_(25-02-2023).png",
        "/images/3months_(25-02-2023)/Video1_(25-02-2023).jpg",
        "/images/3months_(25-02-2023)/Video3_(25-02-2023).jpg",
        "/images/3months_(25-02-2023)/Video4_(25-02-2023).jpg",
        "/images/3months_(25-02-2023)/Client5_(25-02-2023).PNG",
        "/images/3months_(25-02-2023)/Client6_(25-02-2023).PNG",
        "/images/3months_(25-02-2023)/Client7_(25-02-2023).PNG",
        // Add more images as needed
    ];
    // Array of images
    const images_1m = [
        "/images/1month_(24-12-2022)/Client4_(24-12-2022).png",
        "/images/1month_(24-12-2022)/Client6_(24-12-2022).png",
        "/images/1month_(24-12-2022)/Client11_(24-12-2022).png",
        "/images/1month_(24-12-2022)/Client13_(24-12-2022).png",
        "/images/1month_(24-12-2022)/Client9_(24-12-2022).png",
        "/images/1month_(24-12-2022)/Client10_(24-12-2022).png",
        // Add more images as needed
    ];

    return (
        <div className="development" ref={contentRef0}>
            <h1>
                Development Progress
            </h1>
            <div className="development_description">
                <p>
                    We started the development of ArqaeTheGame in november 2022 and have kept track of our progress since then. 
                    <br></br>
                    Click around to find pictures and descriptions of some of our milestones. 
                </p>

                <button className="button" onClick={() => scrollToContent(contentRef1)}>
                    2024
                    </button>
                <button className="button" onClick={() => scrollToContent(contentRef2)}>
                    2023
                    </button>
                <button className="button" onClick={() => scrollToContent(contentRef3)}>
                    2022
                    </button>
                </div>

            <div className="development_section" ref={contentRef1}>
                <h1 className="year_title">
                    2024
                </h1>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Nov. 2023 - Feb. 2024 
                            <br></br>
                            <div className="date_extra">(1 Year & 3 Months of Development)</div>
                        </h1>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> ArqaeTheGame.dk <div className="extra_info">- (Began development of this website to showcase ArqaeTheGame)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Dialogue System Update<div className="extra_info">- (Dialogue from NPC's can now adapts based on quests and other conditions (Now players can also access merchant shops through dialogue))</div> <br></br>     
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Quest/Dialogue Update <div className="extra_info">- (Players can now both accept and deliver quests at NPCs. Completing quests may unlock new quests at other NPCs, progressing the storyline)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Clothing System for Players <div className="extra_info">- (Implemented a clothing system that functions with player equipment)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Block System Update <div className="extra_info">- (Improved block system with a visual shield UI that depletes when blocking and recovers after release)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Armor Stats <div className="extra_info">- (Added armor stats to all equipment types except weapons)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Quest Icons <div className="extra_info">- (Added quest icons above NPCs for quest acceptance and completion)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Loading Screen <div className="extra_info">- (Implemented a short loading screen before entering the world)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Faction Tower Update <div className="extra_info">- (The faction towers on the map now indicate the controlling faction by changing the color of the tower's flags)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> 3 New Skills <div className="extra_info">- (Introduced 3 new healing skills: 2 AoE healing skills and 1 single-target projectile healing skill)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Upgraded to HDRP <div className="extra_info">- (The whole project has been upgraded to use HDRP (High Definition Render Pipeline) for improved visuals)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Skybox <div className="extra_info">- (Added a skybox for dynamic sky visuals)</div> <br></br> 
                        {/* <p>Coming soon...</p>      */}
                    </div>
                    <Gallery images={images_1y3m}/>
                </div>
                <button className="back_to_top" onClick={() => scrollToContent(contentRef0)}>
                    Back to top
                </button>
            </div>
            <div className="development_section" ref={contentRef2}>
                <h1 className="year_title">
                    2023
                </h1>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Aug. 2023 - Nov. 2023 
                            <br></br>
                            <div className="date_extra">(1 Year of Development)</div>
                        </h1>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Clothing System for NPC's <div className="extra_info">- (NPC's now have the new human model along with new clothing options: a shirt, pants & a hairstyle)</div> <br></br>     
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Camera Collisions <div className="extra_info">- (The camera now zooms in when colliding with objects to prevent clipping through walls, etc.)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Skill Tree Update <div className="extra_info">- (Players can now have multiple different skill trees active simultaneously)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Leveling Reward System <div className="extra_info">- (Upon reaching a new level, players can claim their rewards. (Currently, rewards consist of currency; more rewards to come...))</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Bulletin Board Update <div className="extra_info">- (When viewing the bulletin board for quests, players no longer zoom in on the board. Instead, a new UI menu appears where quests can be both accepted and completed)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> NPC Merchant Update <div className="extra_info">- (NPC merchants now offer items for sale in addition to serving as places where players can only sell their items)</div> <br></br>  
                    </div>
                    <Gallery images={images_1y}/>
                </div>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            May 2023 - Aug. 2023 
                            <br></br>
                            <div className="date_extra">(9 Months of Development)</div>
                        </h1>
                        <div className="test">
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Switched to Third Person Camera <div className="extra_info">- (The game is no longer first person perspective)</div> <br></br>     
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Human Model <div className="extra_info">- (Players now have an actual human model instead of being a capsule)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Animations <div className="extra_info">- (New animations include idle, idle combat stance, fist attack combo, staff attack, block, heal, and hand skill)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Quest Jounal <div className="extra_info">- (A menu(Journal) has been added to keep track of all current quests)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Status Effect System <div className="extra_info">- (Current debuffs include: damage over time & slow. Current buffs include: healing over time & speed)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Tooltip System <div className="extra_info">- (All items and skill slots now display visual tooltips when hovered over)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Spirit System <div className="extra_info">- (Quick Attack grants you spirit, while other skills consume spirit to cast. Spirit also recovers over time)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Xbox Gamepad Compatibility <div className="extra_info">- (Includes aim assist for Xbox gamepad users)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Skill Tree System Update <div className="extra_info">- (All skills in the skill tree can now be upgraded 3 times after unlocking. Fully upgrading a core skill gives the ability to unlock 1 of 3 morphs for the skill.)</div> <br></br>                    
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Dummy <div className="extra_info">- (Every camp now has at least one dummy for practicing skills)</div> <br></br>    
                        <Icon className="icon"> <ConstructionIcon/> </Icon> New Crafting Stations: Tailoring & Blacksmithing <div className="extra_info">- (Tailoring is available at the loom and sewing machine, while blacksmithing is done at the anvil)</div> <br></br>   
                        <Icon className="icon"> <ConstructionIcon/> </Icon> New Crafting Recipes: Linen Fabric & Wool Fabric <div className="extra_info"></div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> New Material Deposits: Copper Vein & Iron Vein <div className="extra_info"></div> <br></br>                
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simple Queue System <div className="extra_info">- (Players can select 1 of 3 roles: tank, healer, or damage dealer, and queue up for a trial with 3 other players. (The actual trials are still in progress))</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> World Update <div className="extra_info">- (The game world is no longer within 4 walls, but spans a whole island named Iaman Island, surrounded by water. Additionally, there are three faction towers to capture: The Northern Tower, Western Tower, and Eastern Tower)</div> <br></br>
                        </div>                        
                    </div>
                    <Gallery images={images_9m}/>
                </div>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Feb. 2023 - May 2023 
                            <br></br>
                            <div className="date_extra">(6 Months of Development)</div>
                        </h1>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Storage System <div className="extra_info">- (Each faction now has a chest in their camp with up to 18 slots for storing items)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Skill Tree System <div className="extra_info">- (Players can now unlock 4 skills with skill points. Skill points are gained from earning enough experince whenever a skill hits its target)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> New Skill: Ice Tap <div className="extra_info">- (A single-target projectile skill)</div> <br></br>   
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Guild System <div className="extra_info">- (Players can now create, join, leave, and demolish guilds)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Faction Tower <div className="extra_info">- (A large tower in the world that can be captured by factions. Flags on the side change to the faction's color when captured, and all players receive a notification in their chatbox)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Quick Attack System <div className="extra_info">- (Quick attacks are available for each of the 3 weapons: sword, bow, and staff, replacing previous combat attacks)</div> <br></br>  
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Map <div className="extra_info">- (A map of the world with pan and zoom functions. Icons for faction camps, ruins, windmills, and caves scale according to the zoom level. Players can also see themselves as an arrow)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> New Material Deposit: Gold Vein <div className="extra_info"></div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Enemy AI Update <div className="extra_info">- (Dead enemies now spawn bodies that slowly fade away and can only wander within a certain area)</div> <br></br>                    
                        <Icon className="icon"> <ConstructionIcon/> </Icon> New Enemy Types: Wolf & Big yellow boss <div className="extra_info">- (In addition to enemy capsules, wolves now roam the world, and there is a big yellow world boss capsule)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Very Early Housing System <div className="extra_info">- (Players can now move a chair around and place it again)</div> <br></br>                        
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simple Keybind System <div className="extra_info">- (Players can change keybindings for movement keys in the options menu)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Small World & UI Updates <div className="extra_info"></div> <br></br>                      
                    </div>
                    <Gallery images={images_6m}/>
                </div>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Dec. 2022 - Feb. 2023 
                            <br></br>
                            <div className="date_extra">(3 Months of Development)</div>
                        </h1>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Dialogue System for NPC’s <div className="extra_info">- (Includes dialogue choices)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Basic Quest System <div className="extra_info">- (Currently, all quests appear on a bulletin board in the camp and are turned in to the camp leader through dialogue. Some quests also trigger new quests)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Basic Skill System <div className="extra_info">- (Currently includes 3 skills: a self heal skill, a projectile skill (which works on both players and enemies), and an AoE skill that damages enemies over 3 ticks)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Escape Menu <div className="extra_info">- (Includes 3 buttons: Return, Options, and Exit)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Chatbox Update <div className="extra_info">- (A yellow notification now appears in the chat when a player reaches a new level)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> New Gather Deposit: Mushroom <div className="extra_info"></div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Interact System Update <div className="extra_info">- (Interact text now appears when able to gather a deposit (tree, stone, or mushroom))</div> <br></br>   
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Gathering System Update <div className="extra_info">- (A circle now appears on the screen while gathering a deposit, filling up with each hit to track the remaining hits)</div> <br></br>                    
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Item Pickup Update <div className="extra_info">- (When picking up an item, an animation now slides in on the screen to visualize it)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Equipment Update <div className="extra_info">- (Equipment slots have been updated to include 6 slots: 1-hand, 2-hand, head, chest, belt, and feet)</div> <br></br>     
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Crafting System Update <div className="extra_info">- (Woodworking recipes now include additional items: chest piece, belt, boots, christmas hat, candy cane, machete, and bone dagger)</div> <br></br>                      
                        <Icon className="icon"> <ConstructionIcon/> </Icon> New Crafting Station: Cooking Station <div className="extra_info">- (Includes a new crafting recipe: healing potion.)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Group System Update <div className="extra_info">- (Group icons now follow party members around the screen's edges)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Start Menu Update <div className="extra_info">- (Players can't connect unless a faction and valid username are chosen. Valid usernames must be between 2-16 characters long)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Visual Update to 'The Square' <div className="extra_info">- (The world is now slightly bigger with actual environmental design: hills, trees, grass & a visual update to the cave. Two new points of interest have also been added: a windmill and a ruin to explore)</div> <br></br>                      
                    </div>
                    <Gallery images={images_3m}/>
                </div>
                <button className="back_to_top" onClick={() => scrollToContent(contentRef0)}>
                    Back to top
                </button>
            </div>
            <div className="development_section" ref={contentRef3}>
                <h1 className="year_title">
                    2022
                </h1>
                <div className="container">
                    <div className="row_devlopment_description">
                        <h1>
                            Nov. 2022 - Dec. 2022 
                            <br></br>
                            <div className="date_extra">(1 Month of Development)</div>
                        </h1>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Networking <div className="extra_info">- (Establishing client-server connections, spawning players in the world, and sending player position to other connected players)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Basic movement <div className="extra_info">- (Includes WASD-movement, jumping, and sprinting)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Basic Enemy AI <div className="extra_info">- (Includes random wandering, chasing, and attacking when a player gets close)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Health System <div className="extra_info">- (Implemented for both players and enemies, including death and respawns)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simple Combat Mechanics <div className="extra_info">- (Allows sword attack when left-clicking and shooting an arrow when right-clicking)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Faction system <div className="extra_info">- (Includes 3 factions: Wilders, New Borns, and Sea Raiders. NPCs and crafting stations from other factions than your own are non-interactable. Hostile NPC names appear in red. Interaction with other players is currently unaffected)</div> <br></br>                       
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Interact system <div className="extra_info">- (Displays text when able to interact with something, although currently not showing on harvestable deposits)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Item Pickup <div className="extra_info">- (Automatically adds items to your inventory when walking over them or looting chests)</div> <br></br>     
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simple Inventory <div className="extra_info">- (Includes 9 slots, and are currently without drag-and-drop functionality or stacking)</div> <br></br>                      
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Equipment System <div className="extra_info">- (Includes 3 slots: sword, bow, and headwear. Click an item to equip/unequip)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Simple Gathering System <div className="extra_info">- (Allows left-clicking on trees or stones 3 times to gather. Gathered deposits respawn)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Crafting System <div className="extra_info">- (Woodworking bench - with current crafting recipes: bow, sword & hat)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Experience & Level system <div className="extra_info">- (Caps at level 10, with experience gained from killing enemies and gathering)</div> <br></br> 
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Chatbox <div className="extra_info">- (Enables reading and sending messages to other players in the world)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Group System <div className="extra_info">- (Allows inviting, leaving, and disbanding a group with other players)</div> <br></br>                    
                        <Icon className="icon"> <ConstructionIcon/> </Icon> NPC Merchants <div className="extra_info">- (Currently only for selling items, not buying)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Chests <div className="extra_info">- (Includes random loot generator)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Start Menu <div className="extra_info">- (Includes faction and username choices)</div> <br></br>
                        <Icon className="icon"> <ConstructionIcon/> </Icon> Creation of 'The Square' <div className="extra_info">- (A small square area where players can move freely, containing a camp for each of the 3 factions and a cave in one of the corners)</div> <br></br>                       
                    </div>
                    <Gallery images={images_1m}/>
                </div>
                <button className="back_to_top" onClick={() => scrollToContent(contentRef0)}>
                    Back to top
                </button>
            </div>
        </div>
    )
}