import "./footer.css";

export default function Footer()
{
    return (
    <div className="container">
        <div className="footer">
            <a href="https://discord.gg/ptB24ckwMk" target="_blank" rel="noopener noreferrer"> {/*it's recommended to include rel="noopener noreferrer" for security and performance reasons. This prevents the new page from accessing the window.opener property, which could potentially be a security risk, and ensures that the new tab doesn't inherit the referrer information, which can help with privacy.*/}
                { <img className="img_footer_discord" src="/images/DiscordIcon.ico" alt=""/> }  
            </a>
            {/* <hr className="footer_divider"></hr> */}
            <p> 
                ©{new Date().getFullYear()} ArqaeTheGame. All rights reserved.
            </p>
        </div>
    </div>
    )
}