import { Link,  useMatch, useResolvedPath } from "react-router-dom";

export default function NavBar()
{
    return (
    <nav className="nav"> 
        <Link to="/" className="site-title">
            {/* ArqaeTheGame */}
            {/* { <img className="site_logo" src="/images/Leaf_White.png" alt=""/> } */}
            { <img className="site_ArqaeTheGame" src="/images/ArqaeTheGame2.png" alt=""/> }
            {/* @ATG */}
        </Link>
        <ul>
            <CustomLink to="/development">Development</CustomLink>
            <CustomLink to="/about">About</CustomLink>
        </ul>
    </nav>
    )
}

function CustomLink({ to, children, ...props })
{
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}